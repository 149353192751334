//
// FontAwesome
//

import {config, library, dom} from '@fortawesome/fontawesome-svg-core'

// fix flicker effect caused by turbolinks
config.mutateApproach = 'sync'

import {faExternalLink as farFaExternalLink} from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import {faLink as farFaLink} from '@fortawesome/pro-regular-svg-icons/faLink'
import {faBan as farBan} from '@fortawesome/pro-regular-svg-icons/faBan'
import {faCircle as farCircle} from '@fortawesome/pro-regular-svg-icons/faCircle'
import {faCheck as farCheck} from '@fortawesome/pro-regular-svg-icons/faCheck'
import {faCheckCircle as farCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import {faCheckCircle as fasCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import {faCircleNotch as farCircleNotch} from '@fortawesome/pro-regular-svg-icons/faCircleNotch'
import {faClock as farClock} from '@fortawesome/pro-regular-svg-icons/faClock'
import {faCircle as fasCircle} from '@fortawesome/pro-solid-svg-icons/faCircle'
import {faPen as farPen} from '@fortawesome/pro-regular-svg-icons/faPen'
import {faPen as falPen} from '@fortawesome/pro-light-svg-icons/faPen'
import {faAt as farAt} from '@fortawesome/pro-regular-svg-icons/faAt'
import {faFileAlt as farFileAlt} from '@fortawesome/pro-regular-svg-icons/faFileAlt'
import {faFileArchive as farFileArchive} from '@fortawesome/pro-regular-svg-icons/faFileArchive'
import {faFileExcel as farFileExcel} from '@fortawesome/pro-regular-svg-icons/faFileExcel'
import {faFileImage as farFileImage} from '@fortawesome/pro-regular-svg-icons/faFileImage'
import {faFileImport as farFileImport} from '@fortawesome/pro-regular-svg-icons/faFileImport'
import {faFilePdf as farFilePdf} from '@fortawesome/pro-regular-svg-icons/faFilePdf'
import {faFilePowerpoint as farFilePowerpoint} from '@fortawesome/pro-regular-svg-icons/faFilePowerpoint'
import {faFileWord as farFileWord} from '@fortawesome/pro-regular-svg-icons/faFileWord'
import {faFolderPlus as farFolderPlus} from '@fortawesome/pro-regular-svg-icons/faFolderPlus'
import {faFolder as farFolder} from '@fortawesome/pro-regular-svg-icons/faFolder'
import {faPaperPlane as farPaperPlane} from '@fortawesome/pro-regular-svg-icons/faPaperPlane'
import {faTrashAlt as farTrashAlt} from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import {faTrashAlt as falTrashAlt} from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import {faPlusCircle as farPlusCircle} from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import {faSearch as farSearch} from '@fortawesome/pro-regular-svg-icons/faSearch'
import {faTimes as farTimes} from '@fortawesome/pro-regular-svg-icons/faTimes'
import {faTimes as falTimes} from '@fortawesome/pro-light-svg-icons/faTimes'
import {faTimesCircle as farTimesCircle} from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import {faTimesCircle as fasTimesCircle} from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import {faTag as farTag} from '@fortawesome/pro-regular-svg-icons/faTag'
import {faDownload as farDownload} from '@fortawesome/pro-regular-svg-icons/faDownload'
import {faComment as farComment} from '@fortawesome/pro-regular-svg-icons/faComment'
import {faLongArrowLeft as farLongArrowLeft} from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft'
import {faLongArrowRight as farLongArrowRight} from '@fortawesome/pro-regular-svg-icons/faLongArrowRight'
import {faSync as farSync} from '@fortawesome/pro-regular-svg-icons/faSync'
import {faBalanceScale as farBalanceScale} from '@fortawesome/pro-regular-svg-icons/faBalanceScale'
import {faUniversity as farUniversity} from '@fortawesome/pro-regular-svg-icons/faUniversity'
import {faHandHoldingUsd as farHandHoldingUsd} from '@fortawesome/pro-regular-svg-icons/faHandHoldingUsd'
import {faHandHolding as farHandHolding} from '@fortawesome/pro-regular-svg-icons/faHandHolding'
import {faCalendarCheck as farCalendarCheck} from '@fortawesome/pro-regular-svg-icons/faCalendarCheck'
import {faCalendarAlt as farCalendarAlt} from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import {faDoNotEnter as farDoNotEnter} from '@fortawesome/pro-regular-svg-icons/faDoNotEnter'
import {faSigma as farSigma} from '@fortawesome/pro-regular-svg-icons/faSigma'
import {faMoneyCheckAlt as farMoneyCheckAlt} from '@fortawesome/pro-regular-svg-icons/faMoneyCheckAlt'
import {faSort as farSort} from '@fortawesome/pro-solid-svg-icons/faSort'
import {faSortUp as farSortUp} from '@fortawesome/pro-solid-svg-icons/faSortUp'
import {faSortDown as farSortDown} from '@fortawesome/pro-solid-svg-icons/faSortDown'
import {faQuestion as farQuestion} from '@fortawesome/pro-regular-svg-icons/faQuestion'
import {faQuestionCircle as farQuestionCircle} from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import {faMapMarked as farMapMarked} from '@fortawesome/pro-regular-svg-icons/faMapMarked'
import {faMapMarkerAlt as fasMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import {faMapMarkerAlt as farMapMarkerAlt} from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt'
import {faThList as farThList} from '@fortawesome/pro-regular-svg-icons/faThList'
import {faExclamationTriangle as fasExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import {faBoxesAlt as farBoxesAlt} from '@fortawesome/pro-regular-svg-icons/faBoxesAlt'
import {faAlarmClock as farAlarmClock} from '@fortawesome/pro-regular-svg-icons/faAlarmClock'
import {faToggleOn as farToggleOn} from '@fortawesome/pro-regular-svg-icons/faToggleOn'
import {faToggleOff as farToggleOff} from '@fortawesome/pro-regular-svg-icons/faToggleOff'
import {faEnvelope as farEnvelope} from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import {faFileSignature as farFileSignature} from '@fortawesome/pro-regular-svg-icons/faFileSignature'
import {faThumbsDown as farThumbsDown} from '@fortawesome/pro-regular-svg-icons/faThumbsDown'
import {faThumbsUp as farThumbsUp} from '@fortawesome/pro-regular-svg-icons/faThumbsUp'
import {faSmile as farSmile} from '@fortawesome/pro-regular-svg-icons/faSmile'
import {faPhone as farPhone} from '@fortawesome/pro-regular-svg-icons/faPhone'
import {faGavel as farGavel} from '@fortawesome/pro-regular-svg-icons/faGavel'
import {faClone as farClone} from '@fortawesome/pro-regular-svg-icons/faClone'
import {faBars as farBars} from '@fortawesome/pro-regular-svg-icons/faBars'
import {faGift as farGift} from '@fortawesome/pro-regular-svg-icons/faGift'
import {faIndustryAlt as farIndustryAlt} from '@fortawesome/pro-regular-svg-icons/faIndustryAlt'
import {faHandshake as farHandshake} from '@fortawesome/pro-regular-svg-icons/faHandshake'
import {faHandshakeSlash as farHandshakeSlash} from '@fortawesome/pro-regular-svg-icons/faHandshakeSlash'
import {faTruck as farTruck} from '@fortawesome/pro-regular-svg-icons/faTruck'
import {faChartPie as farChartPie} from '@fortawesome/pro-regular-svg-icons/faChartPie'
import {faUserHardHat as farUserHardHat} from '@fortawesome/pro-regular-svg-icons/faUserHardHat'
import {faSackDollar as farSackDollar} from '@fortawesome/pro-regular-svg-icons/faSackDollar'
import {faTrees as farTrees} from '@fortawesome/pro-regular-svg-icons/faTrees'
import {faDollarSign as farDollarSign} from '@fortawesome/pro-regular-svg-icons/faDollarSign'
import {faFileContract as farFileContract} from '@fortawesome/pro-regular-svg-icons/faFileContract'
import {faPlus as falPlus} from '@fortawesome/pro-light-svg-icons/faPlus'
import {faPlus as farPlus} from '@fortawesome/pro-regular-svg-icons/faPlus'
import {faMinus as farMinus} from '@fortawesome/pro-regular-svg-icons/faMinus'
import {faMinus as falMinus} from '@fortawesome/pro-light-svg-icons/faMinus'
import {faHistory as farHistory} from '@fortawesome/pro-regular-svg-icons/faHistory'
import {faEyeSlash as farEyeSlash} from '@fortawesome/pro-regular-svg-icons/faEyeSlash'
import {faPrint as farPrint} from '@fortawesome/pro-regular-svg-icons/faPrint'
import {faCopy as farCopy} from '@fortawesome/pro-regular-svg-icons/faCopy'
import {faCog as farCog} from '@fortawesome/pro-regular-svg-icons/faCog'
import {faRandom as farRandom} from '@fortawesome/pro-regular-svg-icons/faRandom'

library.add(
    farFaExternalLink,
    farFaLink,
    farBan,
    farCircle,
    farCheck,
    farCheckCircle,
    fasCheckCircle,
    farCircleNotch,
    farClock,
    fasCircle,
    farPen,
    falPen,
    farAt,
    farFileAlt,
    farFileArchive,
    farFileExcel,
    farFileImage,
    farFileImport,
    farFilePdf,
    farFilePowerpoint,
    farFileWord,
    farFolderPlus,
    farFolder,
    farPaperPlane,
    farTrashAlt,
    falTrashAlt,
    farPlusCircle,
    farSearch,
    farTimes,
    falTimes,
    farTimesCircle,
    fasTimesCircle,
    farTag,
    farDownload,
    farComment,
    farLongArrowLeft,
    farLongArrowRight,
    farSync,
    farBalanceScale,
    farUniversity,
    farHandHoldingUsd,
    farHandHolding,
    farCalendarCheck,
    farCalendarAlt,
    farDoNotEnter,
    farSigma,
    farMoneyCheckAlt,
    farSort,
    farSortUp,
    farSortDown,
    farQuestion,
    farQuestionCircle,
    farMapMarked,
    fasMapMarkerAlt,
    farMapMarkerAlt,
    farThList,
    fasExclamationTriangle,
    farBoxesAlt,
    farAlarmClock,
    farToggleOn,
    farToggleOff,
    farEnvelope,
    farFileSignature,
    farThumbsDown,
    farThumbsUp,
    farSmile,
    farPhone,
    farGavel,
    farClone,
    farBars,
    farGift,
    farIndustryAlt,
    farHandshake,
    farHandshakeSlash,
    farTruck,
    farChartPie,
    farUserHardHat,
    farSackDollar,
    farTrees,
    farDollarSign,
    farFileContract,
    falPlus,
    farPlus,
    farMinus,
    falMinus,
    farHistory,
    farEyeSlash,
    farPrint,
    farCopy,
    farCog,
    farRandom,
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to continue doing this as the DOM changes.
dom.watch()
